import { Redirect } from "react-router-dom";
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'semantic-ui-react';
import Validator from 'validator';
import InlineError from '../messages/InlineError.js';

class RegisterForm extends React.Component {
  state = {
    data: {
      email: '',
      password: '',
      token: '',
      name: ''
    },
    loading: false,
    redirect: false,
    errors: {}
  };

  onChange = e =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/login' />
    }
  }


  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length == 0) {
      const data = this.state.data;
      if(data.token == "nd2022"){
        fetch("/register", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: data.email, password: data.password, name: data.name })
        }).then(res => res.json()).then(data => {if (data.registered == 1) this.setRedirect()});
      }
    }
  };

  validate = (data) => {
    const errors = {};
    if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
    if (!data.password) errors.password = "Password can't be blank";
    if (!data.token) errors.token = "Token can't be blank";
    if (!data.name) errors.name = "Name can't be blank";
    return errors;
  }

  render() {
    const { data, errors } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Field error={!!errors.name}>
          <label htmlFor="name" style={{color: 'black'}}>Full Name</label>
          <input
            id="name"
            name="name"
            placeholder="First and Last"
            value={data.name}
            onChange={this.onChange}
            style={{borderColor: "#04376e" }}
          />
          {errors.name && <InlineError text={errors.name} />}
        </Form.Field>
        <Form.Field error={!!errors.email}>
          <label htmlFor="email" style={{color: 'black'}}>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="example@example.com"
            value={data.email}
            onChange={this.onChange}
            style={{borderColor: "#04376e" }}
          />
          {errors.email && <InlineError text={errors.email} />}
        </Form.Field>
        <Form.Field error={!!errors.password}>
          <label htmlFor="password" style={{color: 'black'}}>Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Make it secure"
            value={data.password}
            onChange={this.onChange}
            style={{borderColor: "#04376e" }}
          />
          {errors.password && <InlineError text={errors.password} />}
        </Form.Field>
        <Form.Field error={!!errors.token}>
          <label htmlFor="token" style={{color: 'black', marginLeft: '36%', marginTop: '5%'}}>Company Authorization Token</label>
          <input
            id="token"
            name="token"
            placeholder="Issued by employer"
            value={data.token}
            onChange={this.onChange}
            style={{borderColor: "#04376e", width: '20%', marginLeft: '36%' }}
          />
          {errors.token && <InlineError text={errors.token} />}
        </Form.Field>
        {this.renderRedirect()}
        <Button primary style={{marginLeft: '38%', width: '15%', marginTop: '3%', marginBottom: '10%', height: '10%', backgroundColor: '#03376e', color: '#f1e89b'}}>Register</Button>
      </Form>
    );
  }
}

RegisterForm.propTypes = {
  submit: PropTypes.func.isRequired
};

export default RegisterForm;
