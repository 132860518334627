import React from 'react';
import { Route } from 'react-router-dom';
import Login from './components/pages/login.js';
import Home from './components/pages/home.js';
import Register from './components/pages/register.js'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header.js';
import { Provider } from './context.js';

import ReactGA from 'react-ga'
function initializeAnalytics() {
  ReactGA.initialize("UA-185413000-1")
  ReactGA.pageview('/')
}


function App() {
  initializeAnalytics()
  return (
    <Provider>
    <div>
      <Header/>
      <div className="ui container">
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/" exact component={Home} />
      </div>
    </div>
    </Provider>

  );
}

export default App;
