import { Redirect } from "react-router-dom";
import React from 'react';

class Header extends React.Component{
    
    render() {
        return (
            <nav class='navbar navbar-expand-lg navbar-light bg-primary mb-3 py-0'>
                <h1 class='navbar-brand text-white' id="branding">University of Notre Dame | Un Kilo de Ayuda</h1>
            </nav>
        );
    }
}

export default Header;