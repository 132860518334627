import React, { Component } from 'react';

const Context = React.createContext();

const reducer = (state, action) => {
   switch(action.type) {
      case 'LOG_USER':
         return {
            ...state,
            loggedIn: true
         };
      default:
         return state;
   }
};

// Global state stored here
export class Provider extends Component {
   state = {
     loggedIn: false,
     organization: 'Test',
     dispatch: action => this.setState(state => reducer(state, action))
   }
    render() {
      return(
        <Context.Provider value={this.state}>
          {this.props.children}
        </Context.Provider>
      )
    }
}

export const Consumer = Context.Consumer;