import React from 'react';
import { Redirect } from "react-router-dom";
import { Form, Button, Dropdown } from 'semantic-ui-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class Home extends React.Component {
	  constructor(props){
		      super(props);
		      this.state = {
			            columnDefs: [
					            { headerName: "CLUES", field: "clues"},
					            { headerName: "Estado", field: "state"},
					            { headerName: "Municipio", field: "municipality"},
					            { headerName: "Comunidad", field: "community"},
					            { headerName: "Tipología", field: "typology" },
					            { headerName: "Nombre de la Unidad", field: "unit"},
					            { headerName: "Código Postal", field: "postal" }
					          ],
			            data: {
					            state: '',
					            municipality: '',
					            community: '',
					            zip: '',
					            radius: '10'
					          },
			            defaultColDef: {
					            filter: true
					          },
			            domLayout: 'autoHeight',
			            status_check: 0,
			            state_list: [
					            {key: 'Sinaloa', value: 'Sinaloa', flag: 'Sinaloa', text: 'Sinaloa'},
					            {key: 'Mexico', value: 'Mexico', flag: 'Mexico', text: 'Mexico'},
					            {key: 'Oaxaca', value: 'Oaxaca', flag: 'Oaxaca', text: 'Oaxaca'},
					            {key: 'Chiapas', value: 'Chiapas', flag: 'Chiapas', text: 'Chiapas'},
					            {key: 'Yucatan', value: 'Yucatan', flag: 'Yucatan', text: 'Yucatan'}
					          ],
			            municipality_list: [],
			            community_list: [],
			            locations: [],
			            clinics: [],
			            rows: []
			          }
		    };

	  onChange = (e, {value}) => {
		      this.setState({
			            data: { ...this.state.data, state: value }
			          });
		          fetch("/update_municipalities", {
				                        method: "POST",
				                        headers: { "Content-Type": "application/json" },
				                        body: JSON.stringify({ state: value})
				                      }).then(res => res.json()).then(data => { this.setState({ municipality_list: data.municipality_list})})
		          }

	  handleChange = (e, {value}) => {
		      this.setState({data: { ...this.state.data, municipality: value}});
		      fetch("/update_communities", {
			            method: "POST",
			            headers: { "Content-Type": "application/json" },
			            body: JSON.stringify({ municipality: value})
			          }).then(res => res.json()).then(data => { this.setState({ community_list: data.community_list})})
		    }

	  handleChange_2 = (e, {value}) => {
		      this.setState({data: { ...this.state.data, community: value}});
		    }

	  onSubmit = () => {
		        const data = this.state.data;
		        fetch("/", {
				        method: "POST",
				        headers: { "Content-Type": "application/json" },
				        body: JSON.stringify({ state: data.state, municipality: data.municipality, community: data.community, zip: data.zip, radius: data.radius })
				      }).then(res => res.json()).then(data => {
					              this.setState({ status_check: data.type, rows: data.locations});
					            })
		      }


	    componentDidMount() {
		          fetch('/summary', {method: "GET",
				                    headers: { "Content-Type": "application/json", 'Accept': 'application/json' },
				                  }).then(response => response.json()).then(data => this.setState({ rows: data.clinics}));
		        }



	  render() {
		      const {data} = this.state;
		      const {value} = this.state.data.municipality;
		      const {value_2} = this.state.data.community;
		      const {value_3} = this.state.data.state;
		      return(
			            <div class="container my-4">
			                <form>
			                  <div class = "form-row mt-3 mb-3">
			                      <div class="form-group col-md-4">
			                          <label for="estado">Estado</label>
			                          <Dropdown
			                            placeholder="Selecciona Estado"
			                            fluid
			                            search
			                            selection
			                            options={this.state.state_list}
			                            onChange={this.onChange}
			                            value={value_3}
			                          />
			                      </div>
			                      <div class="form-group col-md-4">
			                          <label for="municipio">Municipio</label>
			                          <Dropdown
			                              placeholder="Selecciona Municipio"
			                              fluid
			                              search
			                              selection
			                              options={this.state.municipality_list}
			                              onChange={this.handleChange}
			                              value={value}
			                          />
			                      </div>
			                      <div class="form-group col-md-4">
			                          <label for="communidad">Comunidad</label>
			                          <Dropdown
			                            placeholder="Selecciona Comunidad"
			                            fluid
			                            search
			                            selection
			                            options={this.state.community_list}
			                            onChange={this.handleChange_2}
			                            value={value_2}
			                          />
			                      </div>
			                  </div>

			                  <div class="form-group row justify-content-md-center mt-3 mb-3">
			                      <label for="radiusInput" class="col-sm-3 col-form-label">Buscar códigos postales dentro de (solamente usa con otros filtros)</label>
			                      <div class="col-sm-2">
			                         <select class="form-control" name="radius" id="radiusForm" onChange={this.onChange}>
			                              <option selected value="10">10 km</option>
			                              <option value="25">25 km</option>
			                              <option value="50">50 km</option>
			                              <option value="100">100 km</option>
			                          </select>
			                      </div>
			                      <label for="zipcode" class="col-sm-1 col-form-label">of:</label>
			                      <input type="community" name="zip" class="form-control col-sm-2" id="zipInput" placeholder="07960" value={data.zip} onChange={this.onChange}/>
			                  </div>

			                  <div class="row justify-content-center mt-3 mb-3">
			                      <div class="col-md-4">
			                          <button onClick={this.onSubmit} type="button" class="btn btn-primary btn-lg btn-block">Buscar Clínicas Cercanas</button>
			                      </div>
			                  </div>
			                </form>

			              <div class="row justify-content-center mt-3 mb-3">
			                  <div className="ag-theme-balham" style={{ width: '100%', margin: 'auto' }} >
			                      <AgGridReact
			                          columnDefs={this.state.columnDefs}
			                          defaultColDef={this.state.defaultColDef}
			                          rowData={this.state.rows}
			                          domLayout={this.state.domLayout}
			                      />
			                  </div>
			              </div>
			              <script src="scripts/main.js" type="text/javascript"></script>
			            </div>
			          )
		    }
}

export default Home;
