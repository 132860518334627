import React from 'react';
import LoginForm from '../forms/loginForm.js'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Form, Button } from 'semantic-ui-react';
// import { login } from '../../actions/auth.js';

document.body.style = 'background: #ffffff;';
class Login extends React.Component {
  submit = data =>
    this.props.history.push('/');
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setRedirect()
  }
  state = {
    redirect: false
  };
  setRedirect = () => {
    this.setState({
      redirect: true
    });
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/register' />
    }
  }
  render() {
    return (
        <div style={{width: '100%', margin: 'auto'}}>
          <img
            src="https://i.pinimg.com/originals/ac/f0/c6/acf0c6bf3c048e4721d34a671f53bded.jpg"
            alt="logo"
            style={{width: '60%', marginTop: '10%', marginBottom: '5%', marginLeft: '17%'}}
            />
            <LoginForm submit={this.submit} />
            {this.renderRedirect()}
            <Button onClick={this.handleClick} primary style={{marginLeft: 425, width: 200, marginTop: 25, marginBottom: 50, height: 50, backgroundColor: '#03376e', color: '#f1e89b' }}>Sign Up</Button>
        </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Login;
