import { Redirect } from "react-router-dom";
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'semantic-ui-react';
import Validator from 'validator';
import InlineError from '../messages/InlineError.js';
import { Consumer } from '../../context.js';

class LoginForm extends React.Component {

  state = {
    data: {
      email: '',
      password: ''
    },
    loading: false,
    redirect: false,
    errors: {}
  };

  onChange = e =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/' />
    }
  }

  onSubmit = (dispatch) => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length == 0) {
      const data = this.state.data;
      fetch("/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: data.email, password: data.password })
      }).then(res => res.json()).then(data => {if (data.found == 1) {
        dispatch({type: 'LOG_USER', payload: ''});
        this.setRedirect();
      }});
    }
  }

  validate = (data) => {
    const errors = {};
    if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
    if (!data.password) errors.password = "Can't be blank";
    return errors;
  }
  // This piece below implements a consumer
  render() {
    const { data, errors } = this.state;
    return (
      <Consumer>
        {value => {
          const { dispatch } = value;
          return (
            <Form onSubmit={this.onSubmit.bind(this, dispatch)}>
              <Form.Field error={!!errors.email}>
                <label htmlFor="email" style={{color: 'black'}}>Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="example@example.com"
                  value={data.email}
                  onChange={this.onChange}
                  style={{borderColor: "#04376e" }}
                />
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
              <Form.Field error={!!errors.password}>
                <label htmlFor="password" style={{color: 'black'}}>Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Make it secure"
                  value={data.password}
                  onChange={this.onChange}
                  style={{borderColor: "#04376e"}}
                />
                {errors.password && <InlineError text={errors.password} />}
              </Form.Field>
              {this.renderRedirect()}
              <Button primary style={{marginLeft: 425, width: 200, marginTop: 25, height: 50, backgroundColor: '#03376e', color: '#f1e89b' }}>Login</Button>
            </Form>
          );
        }}
      </Consumer>
    );
  }

  /*
  // This piece below is without a consumer
  render() {
    const { data, errors } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Field error={!!errors.email}>
          <label htmlFor="email" style={{color: 'black'}}>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="example@example.com"
            value={data.email}
            onChange={this.onChange}
            style={{borderColor: "#04376e" }}
          />
          {errors.email && <InlineError text={errors.email} />}
        </Form.Field>
        <Form.Field error={!!errors.password}>
          <label htmlFor="password" style={{color: 'black'}}>Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Make it secure"
            value={data.password}
            onChange={this.onChange}
            style={{borderColor: "#04376e"}}
          />
          {errors.password && <InlineError text={errors.password} />}
        </Form.Field>
        {this.renderRedirect()}
        <Button primary style={{marginLeft: 425, width: 200, marginTop: 25, height: 50, backgroundColor: '#03376e', color: '#f1e89b' }}>Login</Button>
      </Form>
    );
  } */
};

LoginForm.propTypes = {
  submit: PropTypes.func.isRequired
};

export default LoginForm;
