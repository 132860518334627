import React from 'react';
import RegisterForm from '../forms/registerForm.js'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

document.body.style = 'background: #ffffff;';
class Register extends React.Component {
  submit = () =>
    this.props.history.push('/');

  render() {
    return (
        <div style={{width: '100%', margin: 'auto'}}>
          <img
            src="https://i.pinimg.com/originals/ac/f0/c6/acf0c6bf3c048e4721d34a671f53bded.jpg"
            alt="logo"
            style={{width: '60%', marginTop: '10%', marginBottom: '5%', marginLeft: '17%'}}
          />
            <RegisterForm submit={this.submit} />
        </div>
    );
  }
}

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

export default Register;
